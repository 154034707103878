import { motion } from 'framer-motion';
import styled from 'styled-components';
import { ImageAndText } from './ImageAndText';
import { ImageGallery } from './ImageGallery';

export const WorkContainer = styled(motion.div)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin-bottom: 3.5rem;

  ${ImageAndText} {
    margin-top: 7.2rem;
    margin-bottom: 3.5rem;
    grid-row: 2;
  }

  ${ImageGallery} {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto minmax(520px, 1fr) auto;
    grid-column-gap: 2.8rem;
    margin-top: 0;
    margin-bottom: 0;

    ${ImageAndText} {
      grid-column: 3 / -1;
      grid-row: 2;
      justify-content: center;
      margin-top: 0;
      margin-bottom: 0;
    }

    ${ImageGallery} {
      grid-column: 3 / 12;
      grid-row: 2;
      justify-content: center;
    }
  }

  @media only screen and (min-width: 1024px) and (min-height: 1080px) {
    grid-template-rows: 70px 1fr 70px;
  }
`;
