import styled from 'styled-components';

export const TextBioFarm = styled.div`
  order: 2;

  h1 {
    margin-bottom: 3.7rem;
  }
  p {
    font-size: 2.4rem;
    line-height: 2.8rem;
    padding-right: 1rem;
    margin-top: 0;
  }
  @media (min-width: 1024px) {
    padding: 0;
    margin-left: 75px;
  }
`;
