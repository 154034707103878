import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --grey: #777676;
    --white: #FFF;
    --black: #1D1A1C;
    --grey80: rgba(119, 118, 118, 0.8);

    --navHeight: 77px;
    --footerHeight: 175px;

    // Grid
    --gridWidth: 1024px;
    --gutter: 3.2rem;
  }

  * {
    box-sizing: border-box;
  }

  html {
      font-size: 10px;
      min-height: 100%;
    }

    body {
      font-size: 2rem;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      min-height: 100%;
      overflow-y: ${({ isOpen }) => (isOpen ? 'hidden' : 'unset')};
      margin: 0;
      color: #777676;
    }

  img {
    max-width: 100%;
  }

   .grey {
    background-color: #777676;
    color: #FFF;
    transition: background-color .5s;

     a {
       color: #FFF;
     }
  }

  .white {
    background-color: white;
    color: #777676;
    transition: background-color .5s;

    a {
       color: #777676;
     }
  }
`;

export default GlobalStyles;
