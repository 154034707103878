import styled from 'styled-components';

export const Exit = styled.div`
  font-family: interstate-mono, monospace;
  padding: 20px;

  &:hover {
    cursor: pointer;
  }

  &:before,
  &:after {
    position: absolute;
    right: 15px;
    content: ' ';
    height: 22px;
    top: 0px;
    width: 2px;
    background-color: var(--grey);
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

export const Header = styled.h1`
  margin-bottom: 3.7rem;
  text-transform: uppercase;
`;

export const ImageGallery = styled.div`
  padding-top: 2rem;
  margin-top: -5rem;
  align-self: center;
  background-color: var(--white);
  z-index: 50;
`;
