import styled from 'styled-components';

export const PaginationStyles = styled.div`
  display: flex;
  align-items: flex-end;
  z-index: 20;
  margin-bottom: 1.5rem;
  flex-direction: row;
  justify-content: center;

  position: ${({ showImageGallery }) =>
    showImageGallery ? 'unset' : 'relative'};
  @media (min-width: 1024px) {
    margin: -6.6rem 0 1.2rem 0;
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
`;
