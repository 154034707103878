import styled from 'styled-components';

export const ImageBio = styled.div`
  order: 1;

  ul {
    padding: 0;
    margin-top: 0;
    margin-bottom: 2.7rem;
    list-style: none;
  }

  @media (min-width: 1024px) {
    margin-left: 75px;
    order: 2;
  }
`;
