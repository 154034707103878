import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

export default function Navbar({ url, isOpen, setIsOpen }) {
  const [isWhiteColor, setIsWhiteColor] = useState(true);
  const [currentPageName, setCurrentPageName] = useState('');

  useEffect(() => {
    if (url === '/') {
      setIsWhiteColor(true);
      setCurrentPageName('Work');
    } else if (url === '/knut-wold' || url === '/knut-wold/') {
      setIsWhiteColor(false);
      setCurrentPageName('Knut Wold');
    } else if (url === '/contact' || url === '/contact/') {
      setIsWhiteColor(true);
      setCurrentPageName('Contact');
    } else if (url === '/the-farm' || url === '/the-farm/') {
      setIsWhiteColor(true);
      setCurrentPageName('Sørum Gård');
    }
  }, [url, currentPageName]);

  return (
    <NavbarStyles isOpen={isOpen}>
      <CurrentPageLink
        isOpen={isOpen}
        isWhiteColor={isWhiteColor}
        onClick={() => setIsOpen(!isOpen)}
        className={
          url === '/the-farm' || url === '/the-farm/'
            ? 'active navWhite'
            : 'active navGrey'
        }
      >
        <Link to={`${url}`}>{currentPageName || ''}</Link>
      </CurrentPageLink>

      <NavbarWrapper isWhiteColor={isWhiteColor} isOpen={isOpen}>
        <MobileUl>
          <ListItem
            isWhiteColor={isWhiteColor}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="active"
          >
            <Link to={`${url}`}>{currentPageName || ''}</Link>
          </ListItem>

          {url !== '/' && (
            <ListItem
              isWhiteColor={isWhiteColor}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className={url === '/' ? 'active navGrey' : ''}
            >
              <Link to="/">Work</Link>
            </ListItem>
          )}

          {url !== '/knut-wold' && (
            <ListItem
              isWhiteColor={isWhiteColor}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className={url === '/knut-wold' ? 'active navWhite' : ''}
            >
              <Link to="/knut-wold">Knut Wold</Link>
            </ListItem>
          )}

          {url !== '/contact' && (
            <ListItem
              isWhiteColor={isWhiteColor}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className={url === '/contact' ? 'active navGrey' : ''}
            >
              <Link to="/contact">Contact</Link>
            </ListItem>
          )}

          {url !== '/the-farm' && (
            <ListItem
              isWhiteColor={isWhiteColor}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className={url === '/the-farm' ? 'active navGrey' : ''}
            >
              <Link to="/the-farm">Sørum Gård</Link>
            </ListItem>
          )}
        </MobileUl>

        <DesktopUl>
          <ListItem
            isWhiteColor={isWhiteColor}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={url === '/' ? 'active navGrey' : ''}
          >
            <Link to="/">Work</Link>
          </ListItem>

          <ListItem
            isWhiteColor={isWhiteColor}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={url === '/knut-wold' ? 'active navWhite' : ''}
          >
            <Link to="/knut-wold">Knut Wold</Link>
          </ListItem>

          <ListItem
            isWhiteColor={isWhiteColor}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={url === '/contact' ? 'active navGrey' : ''}
          >
            <Link to="/contact">Contact</Link>
          </ListItem>

          <ListItem
            isWhiteColor={isWhiteColor}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={url === '/the-farm' ? 'active navGrey' : ''}
          >
            <Link to="/the-farm">Sørum Gård</Link>
          </ListItem>
        </DesktopUl>
      </NavbarWrapper>
    </NavbarStyles>
  );
}

const MobileUl = styled.ul`
  margin: 0;
  padding: 0;
  z-index: 50;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const DesktopUl = styled.ul`
  display: none;
  margin: 0;
  padding: 0;
  @media (min-width: 1024px) {
    display: unset;
  }
`;

const NavbarWrapper = styled.div`
  @media only screen and (max-width: 1024px) {
    position: absolute;
    background-color: ${({ isWhiteColor }) =>
      isWhiteColor ? 'var(--white)' : 'var(--grey)'};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 100vh;
    z-index: 30;

    padding: 6.5rem 3.2rem 7rem 3.2rem;

    opacity: ${({ isOpen }) => (isOpen ? `1` : `0`)};
    display: ${({ isOpen }) => (isOpen ? `block` : `none`)};
  }
`;

const CurrentPageLink = styled.div`
  z-index: 50;
  position: relative;
  display: ${({ isOpen }) => (isOpen ? 'none' : 'block')};

  color: ${({ isWhiteColor }) =>
    isWhiteColor ? 'var(--grey)' : 'var(--white)'};

  background-color: ${({ isWhiteColor }) =>
    isWhiteColor ? 'var(--white)' : 'var(--grey)'};

  a {
    font-weight: 400;
    font-style: normal;
    font-family: interstate-mono, monospace;

    cursor: pointer;
    text-transform: uppercase;
    display: inline-block;
    /* padding-top: 7rem; */
    border-bottom: ${({ isWhiteColor }) =>
      isWhiteColor
        ? '2px solid var(--grey) !important'
        : '2px solid var(--white) !important'};

    @media only screen and (min-width: 1024px) {
      display: none;
      border-bottom: 2px solid transparent;
      justify-content: space-between;
    }

    &:after {
      position: absolute;
      right: 0;
      content: '';
      width: ${({ isOpen }) => (isOpen ? '20px' : '56px')};
      height: ${({ isOpen }) => (isOpen ? '56px' : '20px')};
      font-weight: bold;
      background: inherit;

      background-color: ${({ isWhiteColor }) =>
        isWhiteColor ? 'var(--grey)' : 'var(--white)'};
    }
  }
`;

export const NavbarStyles = styled.nav`
  z-index: 50;
  overflow-y: ${({ isOpen }) => (isOpen ? 'hidden' : 'unset')};
  align-self: flex-end;

  @media (min-width: 1024px) {
    overflow-y: unset;
    align-self: auto;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 2.8rem;
  min-width: 220px;
  font-family: interstate-mono, monospace;
  font-weight: 400;
  font-style: normal;

  @media (min-width: 1024px) {
    justify-content: unset;
  }

  & a {
    color: ${({ isWhiteColor }) =>
      isWhiteColor ? 'var(--grey)' : 'var(--white)'};

    border-color: ${({ isWhiteColor }) =>
      isWhiteColor ? 'var(--grey)' : 'var(--white)'};
    /* border-bottom: 2px solid transparent; */

    align-self: flex-start;
    cursor: pointer;
    text-transform: uppercase;
    transition: all 200ms cubic-bezier(0.1, 0.1, 0.6, 0.85);
    padding-bottom: 6px;
    margin-bottom: 6px;
    text-decoration: none;

    :hover {
      margin-bottom: 10px;
      padding-bottom: 2px;
      border-bottom: ${(props) =>
        props.isWhiteColor
          ? '2px solid var(--grey)'
          : '2px solid var(--white)'};
    }

    @media (min-width: 1024px) {
      border-bottom: 2px solid transparent;
    }
  }

  &.navWhite {
    &:before {
      background-color: var(--white);
    }
    &:after {
      background-color: var(--white);
    }
  }

  &.navGrey {
    &:before {
      background-color: var(--grey);
    }
    &:after {
      background-color: var(--grey);
    }
  }

  &.active {
    margin-bottom: 0;

    &:after {
      content: '';
      width: 20px;
      height: 56px;
      font-weight: bold;

      /* background-color: ${({ isWhiteColor }) =>
        isWhiteColor ? 'var(--white)' : 'var(--grey)'}; */
      background-color: ${({ isWhiteColor }) =>
        isWhiteColor ? 'var(--grey)' : 'var(--white)'};
    }

    @media (min-width: 1024px) {
      margin-bottom: 2.8rem;
      &:after {
        content: none;
      }
      &:before {
        content: '';
        height: 20px;
        width: 56px;
        font-weight: bold;
        margin-right: 2rem;
        margin-bottom: 10px;
      }
    }
  }
`;
