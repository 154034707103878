import styled from 'styled-components';

export const ImageContainerFarm = styled.div`
  display: flex;
  flex-direction: column;
  order: 1;

  & > div {
    margin-bottom: 7.6rem;
  }

  &:hover {
    cursor: zoom-in;
  }

  @media (min-height: 1024px) {
    margin-bottom: 0;
    margin-top: 0;
    justify-content: center;
  }

  @media only screen and (min-width: 1024px) {
    align-self: center;
    flex-direction: row;
    width: unset;

    & > div {
      margin-bottom: 0;
    }
  }
`;
