import { createGlobalStyle } from 'styled-components';
import '@fontsource/darker-grotesque';

const Typography = createGlobalStyle`
  html {
    font-family: 'Darker Grotesque', -apple-system, BlinkMacSystemFont, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }

  p {
    font-size: 1.6rem;
    font-family: 'Darker Grotesque', sans-serif;
    line-height: 2rem;
  }

  h1,h2,h3,h4,h5,h6 {
  font-family: interstate-mono, monospace;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
    margin: 0;
  }

  h1 {
    font-size: clamp(1.9rem, 3vw, 1.9rem);
    letter-spacing: 1.9px;
  }

  h2 {
    margin-bottom: 1rem;
    letter-spacing: 1.9px;
  }

  a {
    letter-spacing: 1.9px;
    color: inherit;
    text-decoration-color: inherit;
    text-decoration: none;
  }

  .center {
    text-align: center;
  }
`;

export default Typography;
