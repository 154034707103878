import { motion } from 'framer-motion';
import styled from 'styled-components';
import { ContactInfo } from './ContactInfo';
import { ImageContainer } from './ImageContainer';

export const ContactStyles = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  margin-top: 7.2rem;
  margin-bottom: 3.5rem;

  ${ContactInfo} {
    grid-column: 1 / -1;
    grid-row: 1;
  }

  ${ImageContainer} {
    grid-column: 1 / -1;
    grid-row: 2;

    img {
      max-height: 550px;
    }
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
  }

  @media (min-width: 1024px) {
    display: flex;
    align-self: center;
    margin-top: 0;

    ${ContactInfo} {
      margin-left: 3rem;
    }

    ${ImageContainer} {
      img {
        max-height: 550px;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (min-height: 1080px) {
    ${ImageContainer} {
      img {
        max-height: 790px;
      }
    }
  }
`;
