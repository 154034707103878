import { motion } from 'framer-motion';
import styled from 'styled-components';
import { ImageAndText } from './ImageAndText';
import { ImageContainerFarm } from './ImageContainerFarm';
import { TextBioFarm } from './TextBioFarm';

export const FarmContainer = styled(motion.div)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin-top: 7.2rem;
  margin-bottom: 3.5rem;

  ${ImageAndText} {
  }

  ${TextBioFarm} {
    grid-column: 1 / -1;
    grid-row: 1;
  }

  ${ImageContainerFarm} {
    grid-column: 1 / -1;
    grid-row: 2;
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto minmax(520px, 1fr) auto;
    grid-gap: 2.8rem;
    margin-top: 0;
    margin-bottom: 0;

    ${ImageAndText} {
      grid-column: 4/ 13;
      grid-row: 2;
    }

    ${ImageContainerFarm} {
      grid-column: 2 / 13;
      grid-row: 2;
    }

    ${TextBioFarm} {
      grid-column: 10 / 13;
      grid-row: 2;
    }
  }

  @media only screen and (min-width: 1024px) and (min-height: 1080px) {
    grid-template-rows: 70px 1fr 70px;

    ${ImageAndText} {
      grid-column: 3/ 13;
      justify-content: center;
    }
  }
`;
