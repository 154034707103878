import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ImageAndText = styled(motion.div)`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-self: center;
    max-height: 790px;
  }
`;
