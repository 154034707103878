import styled from 'styled-components';

export const ContactInfo = styled.div`
  grid-column: 8 / 10;
  min-height: 166px;
  margin-bottom: 7.6rem;

  p {
    font-size: 40px;
  }
  @media (min-width: 1024px) {
    margin-top: 4.6rem;
    margin-bottom: 0;
  }
`;
