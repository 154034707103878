import styled from 'styled-components';

export const BioStyles = styled.div`
  margin-top: 7.2rem;
  margin-bottom: 3.5rem;

  @media (min-width: 1024px) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }
`;
