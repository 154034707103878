import React, { useState } from 'react';
import styled from 'styled-components';
import 'normalize.css';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typograhpy';
import { ImageBio } from './ImageBio';
import { ContactStyles } from './ContactStyles';
import Navbar, { NavbarStyles } from './Navbar';
import { BioStyles } from './BioStyles';
import { WorkContainer } from './WorkContainer';
import { PaginationStyles } from './PaginationStyles';
import { ImageContainerFarm } from './ImageContainerFarm';
import { TextBioFarm } from './TextBioFarm';
import { FarmContainer } from './FarmContainer';
import { FourOhFourPageContainer } from './FourOhFourContainer';

export default function Layout({ children, ...props }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Typography />
      <GlobalStyles isOpen={isOpen} />
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/uya3sdc.css" />
        <title>Knut Wold</title>
        <meta name="description" content="Knut Wold is a norwegian sculptor." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charset="UTF-8" />
        <html lang="en" />
        <meta name="theme-color" content="#fff" />
      </Helmet>
      <LayoutStyles>
        <Navbar
          url={props.location.pathname}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <Main>{children}</Main>
      </LayoutStyles>
    </>
  );
}

const Main = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 90px 1fr auto;
  grid-column-gap: 2.8rem;

  ${ContactStyles} {
    grid-column: 1 / -1;
    grid-row: 2;
  }

  ${ImageContainerFarm} {
    grid-column: 1 / -1;
    grid-row: 2;
  }

  ${TextBioFarm} {
    grid-column: 1 / -1;
    grid-row: 2;
  }

  ${PaginationStyles} {
    grid-column: 1 / -1;
    grid-row: 3;
  }

  ${WorkContainer} {
    grid-column: 1 / -1;
    grid-row: 2;
  }

  ${FarmContainer} {
    grid-column: 1 / -1;
    grid-row: 2;
  }

  ${BioStyles} {
    grid-column: 1 / -1;
    grid-row: 2;
  }

  ${FourOhFourPageContainer} {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 70px auto 100px;

    ${ImageContainerFarm} {
      grid-column: 3;
      grid-row: 2;
      align-items: center;
    }

    ${ImageBio} {
      margin-top: 2.2rem;
    }

    ${TextBioFarm} {
      margin-top: 2.2rem;
      grid-row: 2;
    }

    ${PaginationStyles} {
      grid-column: 2 / -2;
      grid-row: 3;
    }

    ${ContactStyles} {
      grid-row: 2;
      grid-column: 4 / -1;
    }

    ${WorkContainer} {
      grid-column: 1 / -1;
      grid-row: 1 / -1;
    }

    ${FarmContainer} {
      grid-column: 1 / -1;
      grid-row: 1 / -1;
    }

    ${BioStyles} {
      grid-column: 4 / 11;
      grid-row: 2;
    }
  }

  @media only screen and (min-height: 1080px) {
    grid-template-rows: 70px auto 70px;
  }
`;

const LayoutStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 90px auto;
  min-height: 100vh;
  padding: 0 3.2rem;
  max-width: 1920px;
  margin: 0 auto;

  ${Main} {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  ${NavbarStyles} {
    grid-row: 1;
    grid-column: 1 / -1;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 2.8rem;
    grid-template-rows: auto;
    padding: 0 4.4rem;

    ${NavbarStyles} {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
      margin-top: 7rem;
    }
  }
  @media only screen and (min-height: 1080px) {
    grid-template-rows: 70px auto 70px;
  }
`;
