import styled from 'styled-components';

export const ImageContainer = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 438px;

  & > div:first-child {
    order: 2;
  }

  img {
    object-fit: contain !important;
    max-height: 238px;
  }

  &:hover {
    cursor: zoom-in;
  }

  @media only screen and (min-width: 768px) and (min-height: 1080px) {
    img {
      max-height: 520px;
    }
  }

  @media only screen and (min-width: 1024px) {
    align-self: flex-start;
    flex-direction: row;
    width: unset;

    img {
      max-height: 520px;
    }

    & > div:first-child {
      order: 1;
    }
  }

  @media only screen and (min-width: 1024px) and (min-height: 1090px) {
    img {
      max-height: 790px;
    }
  }
`;
